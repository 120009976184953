import {focusInputGroups} from "../../../global/js/checkInputGroups";
import {excludeModal, triggerModal} from "../../../global/js/modal";
import {initJumpmarks} from "../../../global/js/jumpmark";
import {initPhoneCountryFields} from "../../../global/js/phoneCountryField";
import {initInputMaskDate} from "../../../global/js/inputMaskDate";
import {initContentTabs} from "../../../global/js/contentTabs";
import {initScrollActivate} from "../../../global/js/scrollactivate";
import {initRecaptchaForms} from "../../../global/js/recaptchaForm";

window.addEventListener("load", (event) => {
    focusInputGroups();
    triggerModal();
    excludeModal();
    initJumpmarks();
    initPhoneCountryFields();
    initInputMaskDate();
    initContentTabs();
    initScrollActivate();
    initRecaptchaForms();
});
